import { useCallback, useState } from 'react';
import axios from 'axios';
import type { DesignDocument } from '@mcp-artwork/cimdoc-types-v2';
import { isDesignDocumentWithPanels, useCancelableEffect } from '../../../tools';
import type { PanelProperties, PreviewData, PreviewDataLoader, PreviewType } from './types';

interface UseLoadCardData<T extends PreviewType> {
    data: PreviewData<T> | undefined;
    isLoading: boolean;
    cardMetadata: { loadingLabel: string; requestTime?: number; documentUrl?: string };
    hasError: boolean;
    errorMessage: string;
    panels: PanelProperties[];
    panelIndex: number;
    onPreviewLoading: () => void;
    onPreviewLoaded: () => void;
    onPreviewError: () => void;
    onPanelIndexChange: (index: number) => void;
}

async function getPanelsProperties<T extends PreviewType>(
    data: PreviewData<T>,
    signal: AbortSignal,
): Promise<PanelProperties[]> {
    if ('imageUrl' in data) {
        return [];
    }

    let document: DesignDocument;

    if ('document' in data) {
        document = data.document.document;
    } else {
        if (!data.documentUrl) {
            return [];
        }

        const cimDoc = (await axios.get(data.documentUrl, { signal })).data;
        document = cimDoc.document;
    }

    const panelProperties: PanelProperties[] =
        (isDesignDocumentWithPanels(document) ? document.panels : document.surfaces)?.map((panel) => ({
            id: panel.id,
            width: panel.width,
            height: panel.height,
            images: panel.images,
            textAreas: panel.textAreas,
            itemReferences: panel.itemReferences,
        })) ?? [];

    return panelProperties.filter((p) => p.images || p.textAreas || p.itemReferences);
}

function getErrorMessage(error: any): string {
    if (error?.response?.data?.message) {
        return error.response.data.message;
    } else if (error?.response?.data?.error) {
        return error.response.data.error;
    } else if (error?.response) {
        switch (error.response.status) {
            case 400:
                return 'Bad request, please verify input is correct';
            case 414:
                return 'URL is too long.';
            case 422:
                return 'Cannot process given document, please verify input is correct.';
            default:
                return 'Unknown error';
        }
    } else if (error?.message) {
        return error.message;
    } else {
        return 'Unknown error';
    }
}

export function useLoadCardData<T extends PreviewType>(loader: PreviewDataLoader<T>): UseLoadCardData<T> {
    const [data, setData] = useState<PreviewData<T> | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [cardMetadata, setCardMetadata] = useState({ loadingLabel: '' });

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [panels, setPanels] = useState<PanelProperties[]>([]);
    const [panelIndex, setPanelIndex] = useState(0);

    useCancelableEffect(
        (signal) => {
            setData(undefined);
            setIsLoading(true);
            setHasError(false);
            setErrorMessage('');
            setPanels([]);
            setPanelIndex(0);

            function handleError(error: any): void {
                if (signal.aborted) {
                    return;
                }

                setIsLoading(false);
                setHasError(true);
                setErrorMessage(getErrorMessage(error));
            }

            loader({ onStatusUpdate: setCardMetadata, signal })
                .then((data) => {
                    getPanelsProperties(data, signal)
                        .then((panels) => {
                            setPanels(panels);
                            setData(data);
                        })
                        .catch(handleError);
                })
                .catch(handleError);
        },
        [loader],
    );

    const onPreviewLoading = useCallback(() => {
        setIsLoading(true);
        setHasError(false);
        setErrorMessage('');
    }, []);

    const onPreviewLoaded = useCallback(() => {
        setIsLoading(false);
    }, []);

    return {
        data,
        isLoading,
        cardMetadata,
        hasError,
        errorMessage,
        panels,
        panelIndex,
        onPreviewLoading,
        onPreviewLoaded,
        onPreviewError: () => {
            setIsLoading(false);
            setHasError(true);
            setErrorMessage(data?.previewErrorMessage ?? 'Rendering: Unknown error');
        },
        onPanelIndexChange: (index: number) => {
            setPanelIndex(index);
        },
    };
}
