import classNames from 'classnames';
import { ErrorIndicator, LoadingIndicator, PanelSwitcher } from '../components';
import type { PreviewRendererProps } from '../types';
import { useLoadCardData } from '../useLoadCardData';

import classes from '../PreviewCard.module.css';

const noop = () => {};

export const ImageUrlPreviewRenderer = ({ loader }: PreviewRendererProps<'imageUrl'>) => {
    const {
        data,
        isLoading,
        cardMetadata: { loadingLabel },
        hasError,
        errorMessage,
        onPreviewLoaded,
        onPreviewError,
    } = useLoadCardData(loader);

    const imageUrl = data?.imageUrl ?? '';

    return (
        <>
            {isLoading && <LoadingIndicator label={loadingLabel} />}

            {hasError && <ErrorIndicator message={errorMessage} errorUrl={imageUrl} />}

            {!hasError && imageUrl && (
                <div className={classNames(classes.preview, { [classes.hidden]: isLoading })}>
                    <a href={imageUrl} target="_blank" rel="noreferrer">
                        <img src={imageUrl} alt="" onLoad={onPreviewLoaded} onError={onPreviewError} />
                    </a>
                    <span className={classes.noDimensions}>-</span>
                </div>
            )}

            {/* Display PanelSwitcher to keep layout consistent with other card types */}
            {!isLoading && !hasError && <PanelSwitcher panelCount={1} panelIndex={0} onChange={noop} />}
        </>
    );
};
