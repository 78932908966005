import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getRenderingUrl, getRenderingUrlDocument, getRenderingUrlWithPhotoScene } from '../../../../api';
import { projectionMapper } from '../../../../tools';
import { ErrorIndicator, LoadingIndicator, PanelSwitcher, PhotoSceneToggle } from '../components';
import type { PreviewRendererProps } from '../types';
import { useLoadCardData } from '../useLoadCardData';
import classes from '../PreviewCard.module.css';
import { dimensionsToString } from './helpers';

const DebugInfo = lazy(() => import('../components/DebugInfo'));

export const DocumentUrlPreviewRenderer = ({ loader }: PreviewRendererProps<'documentUrl'>) => {
    const [showPhotoScene, setShowPhotoScene] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const previousPreviewUrlRef = useRef<string>('');

    const {
        data: previewData,
        isLoading,
        cardMetadata: { loadingLabel },
        hasError,
        errorMessage,
        panels,
        panelIndex,
        onPreviewLoading,
        onPreviewLoaded,
        onPreviewError,
        onPanelIndexChange,
    } = useLoadCardData(loader);

    useEffect(() => {
        if (!previewData || !previewData.documentUrl) {
            return;
        }

        onPreviewLoading();

        const { documentUrl, backgroundColor, photoSceneUrl, projection, surfaceUrl } = previewData;

        let newPreviewUrl = '';

        if (photoSceneUrl && showPhotoScene) {
            newPreviewUrl = getRenderingUrlWithPhotoScene(documentUrl, photoSceneUrl);
        } else {
            newPreviewUrl = surfaceUrl
                ? getRenderingUrl(documentUrl, panelIndex, surfaceUrl, projectionMapper(projection, 1), backgroundColor)
                : getRenderingUrlDocument(documentUrl, panelIndex, backgroundColor);
        }

        if (newPreviewUrl !== previousPreviewUrlRef.current) {
            previousPreviewUrlRef.current = newPreviewUrl;
            setPreviewUrl(newPreviewUrl);
        } else {
            onPreviewLoaded();
        }
    }, [previewData, panelIndex, showPhotoScene, onPreviewLoading, onPreviewLoaded]);

    const { documentUrl, debug, photoSceneUrl, surfaceUrl } = previewData ?? {};

    const showDebugInfo = !!(photoSceneUrl && debug && documentUrl && surfaceUrl);

    return (
        <>
            {photoSceneUrl && <PhotoSceneToggle value={showPhotoScene} onChange={setShowPhotoScene} />}

            {isLoading && <LoadingIndicator label={loadingLabel} />}

            {hasError && <ErrorIndicator message={errorMessage} errorUrl={previewUrl} />}

            {!hasError && documentUrl && previewUrl && (
                <div className={classNames(classes.preview, { [classes.hidden]: isLoading })}>
                    <a href={documentUrl} target="_blank" rel="noreferrer">
                        <img
                            key={previewUrl}
                            src={previewUrl}
                            alt=""
                            onLoad={onPreviewLoaded}
                            onError={onPreviewError}
                        />
                    </a>
                    <span>{dimensionsToString(panels, panelIndex)}</span>
                </div>
            )}

            {!hasError && showDebugInfo && (
                <Suspense fallback="Loading...">
                    <DebugInfo documentUrl={documentUrl} surfaceUrl={surfaceUrl} />
                </Suspense>
            )}

            <PanelSwitcher panelCount={panels.length} panelIndex={panelIndex} onChange={onPanelIndexChange} />
        </>
    );
};
