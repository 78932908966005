import Header from '@cimpress-technology/react-platform-header';
import { FeatureFlags, GlobalStyles, type FeatureFlagsConfig } from '@cimpress/react-components';
import { createContext, StrictMode, useEffect, useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { auth, initializeAuth, type AuthProfile } from './Auth';
import { ErrorBoundary } from './Components';
import ApiKeyComponent from './Components/common/ApiKeyComponent';
import { ApiKeyWrapper } from './Components/common/ApiKeyWrapper';
import { AGS_API_KEY, API_KEY_PARAM_NAME } from './constants';
import { CategoryNavigation, routes } from './navigation';

const COMPONENT_LIBRARY_FEATURE_FLAGS: FeatureFlagsConfig = {
    v17_noOuterSpacing: true,
};

export const ApiKeyContext = createContext<string>('');

export const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [authToken, setAuthToken] = useState('');
    const [profile, setProfile] = useState<AuthProfile | undefined>(undefined);

    const localApiKey = localStorage.getItem(API_KEY_PARAM_NAME);
    const apiKeyParam =
        new URL(window.location.href.replace('#/', '')).searchParams.get(API_KEY_PARAM_NAME) ||
        localApiKey ||
        AGS_API_KEY;
    const [apiKey, setApiKey] = useState(apiKeyParam);

    useEffect(() => {
        initializeAuth();
        auth.on('tokenExpired', () => {
            setIsLoggedIn(false);
            setAuthToken('');
            setProfile(undefined);
        });
        auth.on('authenticated', () => {
            setIsLoggedIn(true);
            setAuthToken(auth.getAccessToken() ?? '');
            setProfile(auth.getProfile());
        });
    }, []);

    function setApiKeyMaster(apiKey: string) {
        localStorage.setItem(API_KEY_PARAM_NAME, apiKey || '');
        setApiKey(apiKey);
        const apiKeyToSetInUrl = apiKey || AGS_API_KEY;
        window.location.hash = window.location.hash.replace(
            /apiKey=[^&]*/,
            `${API_KEY_PARAM_NAME}=${apiKeyToSetInUrl}`,
        );
    }

    return (
        <StrictMode>
            <FeatureFlags flags={COMPONENT_LIBRARY_FEATURE_FLAGS}>
                <GlobalStyles />
                <HashRouter>
                    <ApiKeyContext.Provider value={apiKey}>
                        <Header
                            // hide collapsible menu on mobile since we don't use it
                            responsive={false}
                            appTitle={<div className="app-title">Artwork Generation Internal Tool</div>}
                            profile={profile}
                            onLogInClicked={() => auth.login({ nextUri: '#/', forceLogin: false })}
                            onLogOutClicked={() => auth.logout('#/')}
                            isLoggedIn={isLoggedIn}
                            accessToken={authToken}
                            notifications={null}
                            showLeftNav
                            appLinks={[
                                {
                                    id: 'Api-Key',
                                    content: <ApiKeyComponent setApiKey={setApiKeyMaster} />,
                                },
                            ]}
                        />
                        <CategoryNavigation />
                        <ErrorBoundary>
                            <Routes>
                                <Route path="" element={<Navigate to={routes[0].href} replace />} />
                                {routes.map(({ href, element }) => (
                                    <Route key={href} path={href} element={<ApiKeyWrapper>{element}</ApiKeyWrapper>} />
                                ))}
                            </Routes>
                        </ErrorBoundary>
                    </ApiKeyContext.Provider>
                </HashRouter>
            </FeatureFlags>
        </StrictMode>
    );
};
