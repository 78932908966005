import { NavTab, NavTabItem } from '@cimpress/react-components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { type RouteCategories, routes, routesByCategory } from '../routes';
import { SubHeaderNavTab } from './SubHeaderNavTab';

import classes from './CategoryNavigation.module.css';

export const CategoryNavigation = () => {
    const [selectedCategory, setSelectedCategory] = useState<RouteCategories>(routesByCategory[0]);
    const { pathname } = useLocation();
    const currentRoute = pathname.substring(1);

    useEffect(() => {
        const currentRouteDefinition = routes.find((route) => route.href === currentRoute);
        if (currentRouteDefinition) {
            setSelectedCategory(
                routesByCategory.find((category) => category.categoryName === currentRouteDefinition.category)!,
            );
        }
    }, [currentRoute]);

    const navigate = useNavigate();
    function routeChange(selectedCategory: RouteCategories) {
        // if the selected category is different from that of the current page, switch to the first page of the selected category
        if (!selectedCategory.routes.find((route) => route.href === currentRoute)) {
            navigate(selectedCategory.routes[0].href);
        }
    }

    return (
        <>
            <NavTab className={classes.navigation}>
                {
                    // do not display error page (last) in the menu
                    routesByCategory.slice(0, -1).map((category) => (
                        <NavTabItem key={category.categoryName} active={category === selectedCategory}>
                            <button
                                onClick={() => {
                                    setSelectedCategory(category);
                                    routeChange(category);
                                }}
                            >
                                {category.categoryName}
                            </button>
                        </NavTabItem>
                    ))
                }
            </NavTab>

            <SubHeaderNavTab
                className={classes.subNavigation}
                routes={selectedCategory.routes}
                currentRoute={currentRoute}
            />
        </>
    );
};
