import type { SelectorOption } from './Components';

export const SOURCE_URL_PARAM = 'sourceUrl';
export const SOURCE_JSON_PARAM = 'sourceJson';
export const USE_SOURCE_JSON_PARAM = 'useSourceJson';
export const TARGET_URL_PARAM = 'targetUrl';
export const TRANSFER_CUSTOMER_ASSETS_PARAM = 'transferCustomerAssets';
export const USE_SMART_TEXT_TRANSFER_PARAM = 'useSmartTextTransfer';
export const TARGET_DECORATION_TECHNOLOGY_PARAM = 'targetDecorationTechnology';

export const NONE_SELECTOR_OPTION: SelectorOption = { label: 'None', value: '' };
export const DEFAULT_PANEL_SELECTOR_OPTION: SelectorOption = { label: 'First', value: 'first' };
export const DEFAULT_CULTURE_OPTION: SelectorOption = { label: 'en-us', value: 'en-us' };
export const DEFAULT_TESTING_CLIENT_SELECTOR_OPTION: SelectorOption = {
    label: 'Playground',
    value: 'ei6RoGWRkvHFEtnza8hrSnlFxT4wsyBnXPa',
};

export const AGS_API_KEY = 'ei6RoGWRkvHFEtnza8hrSnlFxT4wsyBnXPa';
export const API_KEY_PARAM_NAME = 'apiKey';
export const VISTA_TEST_API_KEY = 'doUnpmod9tDLH2ztFqyGyVDKNg7QLEaDpAs';
